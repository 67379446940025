<template>
  <div v-if="user" class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <strong>Beiträge</strong>
            <a
              class="plus"
              href="#"
              data-toggle="modal"
              data-target="#add"
              v-b-tooltip.hover.left
              title="Beitrag erstellen"
            >
              <font-awesome-icon :icon="['fas', 'plus-square']" @click="formModal = true" />
            </a>
          </div>
          <div class="card-body p-2">
            <div v-if="postsData.length > 0">
              <b-table-simple striped class="m-0">
                <b-tr v-for="(element, index) in postsData" :key="index">
                  <b-td>
                    {{ new Date(element.docData.date * 1000).toLocaleDateString() }}
                  </b-td>
                  <b-td>
                    {{ element.docData.title }}
                  </b-td>
                  <b-td>
                    {{ element.docData.keywords.join(", ") }}
                  </b-td>
                  <b-td style="width: 50px; ">
                    <div class="d-flex justify-content-between">
                      <div>
                        <a
                          class="edit ml-4"
                          href="#"
                          data-toggle="modal"
                          data-target="#edit"
                          v-b-tooltip.hover.left
                          title="Bearbeiten"
                          ><font-awesome-icon :icon="['fas', 'edit']" @click="edit(element)"
                        /></a>
                      </div>
                      <div>
                        <a
                          class="delete ml-4"
                          href="#"
                          data-toggle="modal"
                          data-target="#delete"
                          v-b-tooltip.hover.left
                          title="Löschen"
                          ><font-awesome-icon
                            :icon="['fas', 'trash']"
                            @click="
                              deleteId = element.docId;
                              deleteTitle = element.docData.title;
                              deleteModal = true;
                            "
                        /></a>
                      </div>
                    </div>
                  </b-td>
                </b-tr>
              </b-table-simple>
            </div>
            <div class="p-2" v-else>
              <i v-if="!isLoading">Es gibt noch nichts zu sehen. Erstelle den ersten Beitrag.</i>
              <div class="d-flex justify-content-center" v-else>
                <div class="spinner-border text-secondary"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="bv-modal-example" hide-footer v-model="formModal">
      <template #modal-title>
        <div v-if="form.title === ''">
          Beitrag erstellen
        </div>
        <div v-else>
          Beitrag bearbeiten
        </div>
      </template>

      <div class="d-block">
        <b-alert :show="alert.show" variant="danger">{{ alert.content }}</b-alert>
        <label class="font-weight-bold">Titel</label>
        <b-form-input v-model.trim="form.title" placeholder="Titel des Beitrages" text class="mb-3" required></b-form-input>
        <label class="font-weight-bold">Bild</label>
        <b-form-file
          type="file"
          placeholder="Bild auswählen"
          drop-placeholder=""
          class="mb-3"
          ref="Image"
          @change="uploadImage"
          accept="image/*"
        ></b-form-file>
        <div v-show="showImage !== undefined">
          <img :src="form.image" class="img img-responsive mb-3" style="max-width: 466px;" />
          <label class="font-weight-bold">Darstellung des Bildes</label>
          <b-form-select
            v-model="form.imageSize"
            :options="selectFieldOptions"
            class="mb-3"
            v-b-popover.hover="'Hier legst du fest, wie das Bild Dargestellt werden soll.'"
          ></b-form-select>
        </div>
        <label class="font-weight-bold">Schlagwörter</label>
        <b-form-tags
          input-id="tags-basic"
          placeholder="Schlagwort hinzufügen..."
          v-model="form.keywords"
          class="mb-3"
        ></b-form-tags>
        <label class="font-weight-bold">Inhalt</label>
        <quillEditor style="width: 100%;" :options="quillConfig" v-model="form.content"></quillEditor>
      </div>

      <b-button class="mt-3" block @click="save()" variant="primary">Speichern</b-button>
    </b-modal>

    <b-modal ref="delete-modal" hide-footer v-model="deleteModal" title="Löschen">
      <div class="d-block mb-4">
        <p>
          Möchtest du den Beitrag "<b>{{ deleteTitle }}</b
          >" wirklich löschen?
        </p>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="outline-secondary" @click="deleteModal = false" class="mr-2">Behalten</b-button>
        <b-button variant="danger" @click="deletePost(deleteId)">Löschen</b-button>
      </div>
    </b-modal>

    <CheckUserName />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "quill-emoji/dist/quill-emoji.css";

import { quillEditor } from "vue-quill-editor";

import CheckUserName from "./CheckUserName";
import moment from "moment";
import _ from "lodash";

import Quill from "quill";
import QuillEmoji from "quill-emoji";

Quill.register(
  {
    "formats/emoji": QuillEmoji.EmojiBlot,
    "modules/emoji-shortname": QuillEmoji.ShortNameEmoji,
    "modules/emoji-toolbar": QuillEmoji.ToolbarEmoji,
    "modules/emoji-textarea": QuillEmoji.TextAreaEmoji,
  },
  true
);

export default {
  components: {
    quillEditor,
    CheckUserName,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      isLoading: false,
      showImage: undefined,
      deleteId: null,
      deleteTitle: "",
      formId: null,
      postsData: [],
      form: {
        date: 0,
        title: "",
        image: null,
        imageSize: "imageBig",
        keywords: [],
        content: "",
        log: {},
        author: this.$store.getters.user.data.displayName,
      },
      formModal: false,
      deleteModal: false,
      alert: {
        show: false,
        content: "",
      },
      selectFieldOptions: [
        { value: "imageBig", text: "Bild Groß" },
        { value: "imageLeft", text: "Bild links" },
        { value: "imageRight", text: "Bild rechts" },
      ],
      quillConfig: {
        modules: {
          "toolbar": [
            ["bold", "italic"],
            ["emoji"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
          ],
          "emoji-toolbar": {
            buttonIcon: '<div class="i-people" style="margin-top: -3px !important;"></div>',
          },
        },
      },
    };
  },
  watch: {
    formModal(value) {
      if (value === false) {
        this.clear();
      }
    },
  },
  methods: {
    loadPostsData() {
      this.isLoading = true;
      this.$firestore.collection("posts").onSnapshot((snapshot) => {
        this.postsData = [];
        const posts = [];
        snapshot.forEach((doc) => {
          posts.push({
            docId: doc.id,
            docData: doc.data(),
          });
        });
        this.postsData = _.orderBy(
          posts,
          (post) => {
            return post.docData.title.toLowerCase();
          },
          "asc"
        );
        this.postsData.sort((a,b) => b.docData.date - a.docData.date)
        this.isLoading = false;
      });
    },
    uploadImage(event) {
      const storageRef = this.$storage.ref("posts/" + Date.now()).put(event.target.files[0]);

      storageRef.on(
        `state_changed`,
        (snapshot) => {
          console.warn(snapshot);
        },
        (error) => {
          console.error(error);
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.form.image = url;
            this.showImage = url;
          });
        }
      );
    },
    save() {
      if (this.form.title !== "" && this.form.keywords.length > 0) {
        if (this.form.date === 0) {
          this.form.date = moment(new Date()).unix();
        }

        const timestamp = moment().unix();
        this.form.log[timestamp] = this.$store.getters.user.data.displayName;

        if (this.formId === null) {
          this.$firestore.collection("posts").add(this.form);
        } else {
          this.$firestore
            .collection("posts")
            .doc(this.formId)
            .update(this.form);
        }

        this.formModal = false;
        this.clear();
      } else {
        this.showError(true);
      }
    },
    clear() {
      this.formId = null;

      this.form = {
        date: 0,
        title: "",
        image: undefined,
        imageSize: "imageBig",
        keywords: [],
        content: "",
        log: {},
        author: this.$store.getters.user.data.displayName,
      };

      this.showImage = undefined;

      if (this.$refs.Image !== undefined) {
        this.$refs.Image.reset();
      }
    },
    edit(element) {
      this.formId = element.docId;
      this.form = element.docData;
      this.showImage = element.docData.image;
      this.formModal = true;
    },
    deletePost(docId) {
      this.$firestore
        .collection("posts")
        .doc(docId)
        .delete()
        .then(() => {
          this.deleteId = null;
          this.deleteTitle = "";
          this.deleteModal = false;
        })
        .catch((error) => console.error(error));
    },
    showError(state) {
      if (state) {
        this.alert.content = "Bitte trage einen Titel und mindestens ein Schlagwort ein.";
        this.alert.show = true;
      } else {
        this.alert.show = false;
      }
    },
  },
  beforeMount() {
    this.loadPostsData();
  },
};
</script>
<style scoped>
strong, td {
  color: #5c5c5c;
}
a.plus {
  color: rgb(196, 196, 196) !important;
  font-size: 1.1rem;
}
a.plus:hover {
  color: #28a745;
}
a.edit {
  color: rgb(196, 196, 196);
  font-size: 1.1rem;
}
a.edit:hover {
  color: #ffc107;
}
a.delete {
  color: rgb(196, 196, 196);
  font-size: 1.1rem;
}
a.delete:hover {
  color: #dc3545;
}
.topBorder {
  border-top: 1px solid rgb(196, 196, 196);
}
emoji-picker {
  width: 400px;
  height: 300px;
}
.table tr:first-of-type td {
  border-top: 0;
}
</style>
